/**
 * toTop functions
 */
 const toTop = () => {
  const toTop = document.querySelector('.js-to-top')

    toTop?.addEventListener('click', ()=> {
      window.scroll(0,0)
    })

    document.addEventListener('scroll', ()=> {
      btnBehaviour();
    })
  const btnBehaviour = () => {
    if(window.scrollY >= 400){
      toTop?.classList.add('active')
    }else {
      toTop?.classList.remove('active')
    }
  }

  btnBehaviour();
}

export default toTop