/**
 * Header functions
 */
const header = () => {
  const button = document.querySelector('.js-menu-btn')
  const menu = document.querySelector('.js-menu')

  button?.addEventListener('click', ()=> {
    button.classList.toggle('active')
    menu?.classList.toggle('active')
  })
}

export default header