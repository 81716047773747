import header from './components/header'
import toTop from './components/toTop'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    header()
    toTop()
  },
  false
)
